import { Dispatch, useCallback, useEffect, useState } from "react";
import config from "../config/config";
import { makeUrlWithQueryParams } from "../utils/textUtils";

function useButtonLink(authToken: string | null, isTokenValid: boolean) {
    const [androidUrl, setAndroidUrl] = useState("");
    const [iosUrl, setIosUrl] = useState("");
    const [fetchingButtons, setFetchingButtons] = useState(false);
    const [error, setError] = useState(undefined);

    const getApplicationLinks = useCallback(
        async (nameApp: string, urlSetter: Dispatch<string>) => {
            const formData = new URLSearchParams();
            formData.append("format", "json");
            formData.append("name", "ScoutApp");
            formData.append("version", "0.1");

            try {
                setFetchingButtons(true);
                const queryParams = {
                    format: "json",
                    name: nameApp,
                    version: "0.1"
                };
                const appUrl = makeUrlWithQueryParams(`${config.host}/device-app/check`, queryParams);
                const response = await fetch(appUrl, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${authToken}`
                    }
                });

                if (response.status === 200) {
                    const result = await response.json();
                    urlSetter(result.url);
                    return;
                }

                throw new Error(response.statusText);
            } catch (e: any) {
                setError(e);
                setFetchingButtons(false);
            }
        },
        [authToken]
    );

    useEffect(() => {
        let unmounted = false;
        if (!authToken || !isTokenValid || fetchingButtons || error) return;
        if (!unmounted) {
            getApplicationLinks("ScoutApp", setAndroidUrl);
            getApplicationLinks("iScout iOS", setIosUrl);
        }
        return () => {
            unmounted = true;
        };
    }, [androidUrl, iosUrl, authToken, isTokenValid, fetchingButtons, error, getApplicationLinks]);

    return {
        androidUrl,
        iosUrl
    };
}

export default useButtonLink;
