import { ReactNode } from "react";
import useAuthentication from "../hooks/AuthenticatorHooks";
import { UrlContext } from "../state/UrlProvider";
import LoadingPage from "./LoadingPage";
import useButtonUrl from "../hooks/ButtonUrlHooks";

interface Props {
    children: ReactNode;
}

const Authenticator = ({ children }: Props) => {
    const { authToken, authError, code, isTokenValid } = useAuthentication();
    const buttonUrls = useButtonUrl(authToken, isTokenValid);

    if (authError) {
        return <div className="App">{`${authError}`}</div>;
    }

    if (buttonUrls.androidUrl && buttonUrls.iosUrl) {
        return <UrlContext.Provider value={buttonUrls}>{children}</UrlContext.Provider>;
    }

    return <LoadingPage verified={!!code || !!authToken} />;
};

export default Authenticator;
