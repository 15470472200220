const devConfig = {
    client_id: "iscout_download_nTtivtexRhh7fe9ghunsP5LXr6EewMPC0VMWZvn",
    token_endpoint: "https://dev.sportsdata.ag/oauth2/token",
    host: "https://dev.sportsdata.ag",
    appsBackend: "https://web.lda-backend.nonprod.euc1.srlivedata.io"
};

const prodConfig = {
    client_id: "iscout_download_ee8anca613hdkdpoamv972465ddnaxxwquyvbn",
    token_endpoint: "https://www.sportsdata.ag/oauth2/token",
    host: "https://www.sportsdata.ag",
    appsBackend: "https://web.lda-backend.ld2.euc1.srcloud.io"
};

function getConfig() {
    // eslint-disable-next-line no-console
    console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
    switch (process.env.REACT_APP_ENV) {
        case "production":
            return prodConfig;
        case "development":
        default:
            return devConfig;
    }
}

const locationOrigin = typeof window !== "undefined" ? window.location.origin : "";

const config = {
    redirect_uri: locationOrigin,
    authorization_endpoint: "/oauth2/authorize",
    validate_token_endpoint: "/oauth2/token-info",
    ...getConfig()
};

export default config;
