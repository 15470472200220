import { useState, useEffect, useCallback } from "react";
import config from "../config/config";
import { generateRandomString, makeUrlWithQueryParams, sha256} from "../utils/textUtils";

interface AuthData {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
    scope?: string;
}

function useAuthentication() {
    const token = window.sessionStorage.getItem("access_token");
    const expirationDate = parseInt(window.sessionStorage.getItem("expires_in") || "0");
    const verifier = window.sessionStorage.getItem("code_verifier");
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    const [error, setError] = useState(undefined);
    const [fetchingToken, setFetchingToken] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);

    const saveSessionData = (authData: AuthData) => {
        const accessTokenExpirationDate = Date.now() + authData.expires_in * 1000;

        window.sessionStorage.setItem("access_token", authData.access_token);
        window.sessionStorage.setItem("refresh_token", authData.refresh_token);
        window.sessionStorage.setItem("expires_in", `${accessTokenExpirationDate}`);
    };

    const removeSessionData = () => {
        window.sessionStorage.clear();
    };

    const getCode = useCallback(async () => {
        const codeVerifier = verifier || generateRandomString(43);

        if (!verifier) window.sessionStorage.setItem("code_verifier", codeVerifier);

        const codeChallenge = await sha256(codeVerifier);
        const queryParams = {
            response_type: "code",
            client_id: config.client_id,
            redirect_uri: config.redirect_uri,
            state: "xyz",
            code_challenge_method: "S256",
            code_challenge: codeChallenge
        };
        const approveUrl = makeUrlWithQueryParams(`${config.host}/oauth2/authorize`, queryParams);

        window.location.replace(approveUrl);
    }, [verifier]);

    useEffect(() => {
        const actualTime = Date.now();

        if (token && actualTime > expirationDate) {
            removeSessionData();
            getCode();
        } else if (token && actualTime < expirationDate) {
            setIsTokenValid(true);
        }
    }, [token, expirationDate, getCode]);

    useEffect(() => {
        if (token || code || error) return;

        getCode();
    }, [token, code, error, getCode]);

    const getToken = useCallback(async () => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "authorization_code");
        formData.append("code", code || "");
        formData.append("redirect_uri", config.redirect_uri);
        formData.append("code_verifier", verifier as string);

        try {
            setFetchingToken(true);
            const response = await fetch(`${config.host}/oauth2/token`, {
                method: "POST",
                body: formData,
                mode: "cors",
                headers: {
                    Authorization: `Basic ${btoa(`${config.client_id}:`)}`,
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            });

            if (response.status === 200) {
                const result = await response.json();
                saveSessionData(result);
                window.sessionStorage.removeItem("code_challenge");
                setIsTokenValid(true);
                window.location.replace(config.redirect_uri);

                return;
            }

            throw new Error(response.statusText);
        } catch (e: any) {
            setError(e);
            setFetchingToken(false);
        }
    }, [verifier, code]);

    useEffect(() => {
        if (!code || isTokenValid || fetchingToken || error) return;

        if (verifier) getToken();
    }, [code, verifier, isTokenValid, fetchingToken, error, getToken]);

    return {
        authToken: token,
        authError: error,
        code,
        isTokenValid
    };
}

export default useAuthentication;
