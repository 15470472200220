import React from "react";
import "../App.css";

interface Props {
    verified: boolean;
}

const LoadingPage = ({ verified }: Props) => (
    <div className="App">
        {verified ? "Loading page" : "Redirecting you to SSO"}
        <div className="loader" />
    </div>
);

export default LoadingPage;
