import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Authenticator from "./components/Authenticator";
import LandingPage from "./components/LandingPage";

function App() {
    return (
        <Authenticator>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                </Routes>
            </Router>
        </Authenticator>
    );
}

export default App;
