export function generateRandomString(length: number) {
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join("");
}

export function makeUrlWithQueryParams(url: string, params: { [index: string]: any }): string {
    const p: string[] = [];

    for (const key in params) {
        p.push(`${key}=${params[key]}`);
    }

    return `${url}?${p.join("&")}`;
}

export async function sha256(message: string | null): Promise<string> {
    if (message === null) {
        return "";
    }

    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

    const binary: string[] = [];
    const bytes = new Uint8Array(hashBuffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary.push(String.fromCharCode(bytes[i]));
    }

    return btoa(binary.join("")).replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, "");
}
