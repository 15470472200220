import { createContext } from "react";

type Context = {
    androidUrl: string | undefined;
    iosUrl: string | undefined;
};

const context: Context = {
    androidUrl: undefined,
    iosUrl: undefined
};

export const UrlContext = createContext(context);
