import { useContext } from "react";
import "../App.css";
import { UrlContext } from "../state/UrlProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

const LandingPage = () => {
    const { androidUrl, iosUrl } = useContext(UrlContext);
    const iosPrefix = "itms-services://?action=download-manifest&url=";

    return (
        <div className="App">
            <header>
                <h1>iScout</h1>
            </header>
            <section>
                <h2>Sportradar mobile scouting application</h2>
            </section>
            <hr></hr>
            <div className="buttons">
                <a href={`${androidUrl}`}>
                    <button>
                        <FontAwesomeIcon icon={faAndroid} size={"lg"} />
                        <span>ANDROID</span>
                    </button>
                </a>
                <a href={`${iosPrefix}${iosUrl}`}>
                    <button>
                        <FontAwesomeIcon icon={faApple} size={"lg"} />
                        <span>IPHONE</span>
                    </button>
                </a>
            </div>
            <div className="versions">
                <p>
                    <b>Required Android version:</b> 7.0+
                </p>
                <p>
                    <b>Required iOS version:</b> 9.0+
                </p>
            </div>
            <footer>
                <p>
                    <b>Untrusted Enterprise Developer message</b>
                </p>
                <p>
                    <b>iOS 9</b>: Settings {">"} General {">"} Profiles {">"} Sportradar AG {">"} Trust Sportradar AG
                </p>
                <p>
                    <b>iOS 13</b>: Settings {">"} General {">"} VPN & Device Management {">"} Sportradar AG {">"} Trust
                    Sportradar AG
                </p>
            </footer>
        </div>
    );
};

export default LandingPage;
